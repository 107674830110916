import React from 'react';

import Layout from '../components/component.layout';
import SEO from '../components/component.seo';
import HeroBanner from '../components/hero-banner/component.banner';
import Latest from '../components/component.latest';

const NotFoundPage = () => (
    <Layout>
        <SEO title="404: Not found" />
        <HeroBanner title="Not Found" subtitle="You just hit a route that doesn&#39;t exist&hellip;" />
        <Latest />
    </Layout>
);

export default NotFoundPage;
