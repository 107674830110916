import React from 'react';
import { useTrail, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import { graphql, Link, useStaticQuery } from 'gatsby';

import PostOverview from '../components/component.post-overview';

const Latest = ({ title, subtitle }) => {
    const postData = useStaticQuery(graphql`
        query latestPosts {
            wordpress {
                posts(first: 3, where: {orderby: {field: DATE, order: DESC}, status: PUBLISH}) {
                    nodes {
                        title
                        slug
                        date
                        featuredImage {
                            node {
                                altText
                                sourceUrl
                                imageFile {
                                    childImageSharp {
                                        thumbnail: fixed(width: 347, height: 215) {
                                            ...GatsbyImageSharpFixed_withWebp_tracedSVG
                                        }
                                    }
                                }
                            }
                        }
                        categories(first: 1) {
                            nodes {
                                name
                                slug
                            }
                        }
                    }
                }
            }
        }
    `);

    const posts = postData.wordpress.posts.nodes;

    const [ref, inView] = useInView({
        threshold: .3,
        triggerOnce: true,
    });

    const trail = useTrail(posts.length, {
        config: { tension: 280, friction: 60 },
        opacity: inView ? 1 : 0,
        transform: inView ? 'scale(1)' : 'scale(0)',
        from: { opacity: 0, transform: 'scale(0)' },
    });

    return (
        <div className="container">
            <div className="xs-pt-50 xs-pb-20">
                <div className="c-section-title">
                    <h2 className="c-section-title__title">{title ? title : "News and media"}</h2>
                    <p className="c-section-title__desc">{subtitle ? subtitle : "Our latest news, events, tips and advice."} <Link className="c-section-title__link" to="/news/">read more news <i className="c-section-title__arrow fal fa-long-arrow-right"></i></Link></p>
                </div>

                <div ref={ref} className="row">
                    {trail.map(({ ...rest }, index) => (
                        <animated.div key={index} style={rest} className={index < 1 ? "col-md-4" : "col-sm-4 d-none d-md-block"}>
                            <PostOverview post={posts[index]} />
                        </animated.div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Latest;
