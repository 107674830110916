import React, { useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import HeroVideo from './component.video';
import HeroImage from './component.image';

import ThemeContext from '../../context/context.theme';

const HeroBanner = ({ title, subtitle, image, emphasis, video }) => {
    const theme = useContext(ThemeContext);
    const imageData = useStaticQuery(graphql`
        query {
            wintechFile: file(relativePath: { eq: "default-banner.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
            kingFile: file(relativePath: { eq: "default-banner-king.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
        }
    `);

    let defaultPostImageSrc = imageData.wintechFile;
    if(process.env.GATSBY_THEME == "King") {
        defaultPostImageSrc = imageData.kingFile;
    }

    const heroImage = image ? image : defaultPostImageSrc;
    const hasVideo = (video && (video.videoWebm || video.videoMp4)) ? theme.allowVideo : false;

    return (
        <section className="c-hero-banner">
            {hasVideo ? 
                <HeroVideo
                    title={title}
                    subtitle={subtitle}
                    emphasis={emphasis}
                    video={video}
                    poster={heroImage.childImageSharp.fluid.sr}
                />
            :
                <HeroImage
                    title={title}
                    subtitle={subtitle}
                    emphasis={emphasis}
                    image={heroImage.childImageSharp.fluid}
                />
            }
        </section>
    );
};

export default HeroBanner;
